import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { EditMessageContext } from "../../context/EditingMessage/EditingMessageContext";
import toastError from "../../errors/toastError";
import MessageHistoryModal from "../MessageHistoryModal";
import { AuthContext } from "../../context/Auth/AuthContext";

import {
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
  sendDoneColor: {
    color: "red",
  },
  speech2TextColor: {
    color: "#8BC34A",
  },
}));

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl,acceptUser }) => {
  const classes = useStyles();
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { setEditingMessage } = useContext(EditMessageContext);
  const [messageHistoryOpen, setMessageHistoryOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const hanldeReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };

  const handleEditMessage = async () => {
    setEditingMessage(message);
    handleClose();
  }

  const handleOpenMessageHistoryModal = (e) => {
    setMessageHistoryOpen(true);
    handleClose();
  }

  const hanldeDoneMessage = async()=>{
    setLoading(true);
    try {
      const { data } = await api.post("/messages/setDone/" + message.id);
      if(acceptUser && acceptUser.user && acceptUser.user.id){
        await api.put(`/tickets/${message.ticketId}`, {
          status: "open",
          userId:acceptUser.user.id
        });
      }
      setLoading(false);
      handleClose();
      toast.success("success")
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  }

  const hanldeSpeech2Text = async()=>{
    setLoading(true);
    try {
      const { data } = await api.post("/messages/speech/doSpeech2Text",{messageId: message.id, audioUrl: message.mediaUrl});
      if(data.speechResp!=""){
        message.body = message.body+"=>"+data.speechResp;
      }
      setLoading(false);
      handleClose();
      toast.success("success")
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  }

  return (
    <>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <MessageHistoryModal
        open={messageHistoryOpen}
        onClose={setMessageHistoryOpen}
        oldMessages={message.oldMessages}
      >
      </MessageHistoryModal>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
       {message.fromMe && [
          <MenuItem key="delete" onClick={handleOpenConfirmationModal}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>,
          <MenuItem key="edit" onClick={handleEditMessage}>
            {i18n.t("messageOptionsMenu.edit")}
          </MenuItem>
        ]}
        {message.oldMessages?.length > 0 && (
          <MenuItem key="history" onClick={handleOpenMessageHistoryModal}>
            {i18n.t("messageOptionsMenu.history")}
          </MenuItem>
        )}
        <MenuItem key="reply" onClick={hanldeReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
        {!message.fromMe && message.isLast && !message.isDone && [
           <MenuItem className={classes.sendDoneColor} key="Done" onClick={hanldeDoneMessage}>
             {i18n.t("messageOptionsMenu.done")}
           </MenuItem>
        ]}
            {!message.fromMe && message.mediaType==="audio" && message.body.indexOf("=>")<0 && [
           <MenuItem className={classes.speech2TextColor} key="Done" onClick={hanldeSpeech2Text}>
             {i18n.t("messageOptionsMenu.speech2Text")}
           </MenuItem>
        ]}
      </Menu>
    </>
  );
};

MessageOptionsMenu.propTypes = {
  message: PropTypes.object,
  menuOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object
}

export default MessageOptionsMenu;
