import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
	Container
} from '@material-ui/core';
import { Flex } from "antd";
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},
	sidebar: {
		width: 300,
		height: "100vh",
		backgroundColor: "#f5f5f5",
		padding: theme.spacing(2),
	},
	sidebarLink: {
		display: "block",
		padding: 10,
		textDecoration: "none",
		color: "#333",
		"&:hover": {
			backgroundColor: "#f5f5f5",
		},
		"&.active": {
			fontWeight: "bold",
			backgroundColor: "#e6e6e6",
		}
	},
	assistantMain: {
		display: "flex",
		paddingTop: 50
	}
}));

const Sidebar = ({ items, activeItem, setActiveItem }) => {
	const classes = useStyles();
	const handleItemClick = (itemId) => {
	  setActiveItem(itemId);
	};
  
	return (
	  <div className={classes.sidebar}>
		<ul><div>
		{items.map((item) => (
		  <li><a
			key={item.id}
			href={item.href}
			className={`${classes.sidebarLink} ${item.id === activeItem ? 'active' : ''}`}
			onClick={() => handleItemClick(item.id)}
			role="menuitem"
			aria-label={item.name}
		  >
			{item.name}
		  </a></li>
		))}
		</div></ul>
	  </div>
	);
  };

const Gpts = () => {
	const classes = useStyles();
	const [curItmId, setCurItmId] = useState(null);
	return (
		<><div className={classes.assistantMain}><Sidebar items={[{ id: 'olVMixqAUpTP53cm', name: '注册处问题专家', href: '#' }, { id: 'VRSK7mqrFFJEX4Li', name: 'OneStep报价助手', href: '#' },
			{id: 'I2jIAyDO9sjFhmJ4', name: '地址黑名单', href: '#' },{id: 'XoNoWtf8469fqaZr', name: '公司黑名单', href: '#' }, {id: 'VF2tG8u2LOpzggKh', name: '压缩包文件分类助手', href: '#' }, 
			{id: '3ZB3nqTRXBMrEGh5', name: 'CR公司基础信息查询助手', href: '#'},{id: 'AEdHsqpzCfAXlXhP', name:'CR历史文件变动查询助手', href:'#'},{id: 'm3I9hrek2C0xHFBO', name: '文件拆分助手', href: '#'}]} activeItem={curItmId}
		setActiveItem={(itemId) => setCurItmId(itemId)}/>
		{curItmId!=null && (<Container component="main" maxWidth="xs">
			<iframe
			   title="AI partner"
				src={`https://app.ionestep.com/chatbot/${curItmId}`}
				width="500"
				height="800"
				frameborder="0"
				allow="microphone">
			</iframe>
		</Container>)}
		</div>
		</>
	);
};

export default Gpts;
